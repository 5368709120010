<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Bidbonds</a></li>
    </ol>
    <h1 class="page-header">BIDBONDS</h1>
    <panel noButton="true" title="BIDBONDS">
      <template v-if="is_loaded">
        <div v-if="$can.view($store.getters.getAuthUser, 'list-bidbonds')" class="row">
          <div class="input-group col-md-4">
            <input v-model="search" class="form-control py-2 border-right-0 border" type="search"
                   placeholder="Search ...">
            <span class="input-group-append">
            <button class="btn btn-outline-secondary border-left-0 border" type="button">
              <i class="fa fa-search" />
            </button>
          </span>
          </div>
            <date-picker
                aria-placeholder="Start Date"
                v-model="start.date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
            <date-picker
                aria-placeholder="End Date"
                v-model="to.date"
                format="yyyy-MM-dd"
                input-class="form-control bg-white"
            />
          <v-select v-model="pagination.per_page" :options="pages" label="per_page"
                    placeholder="Per Page" />
          <button class="btn btn-secondary ml-2 pull-right" @click="exportBids">
            <i class="fa fa-download"></i> Export
          </button>
        </div>
        <table v-if="bidbonds.length" class="table table-bordered mt-3">
          <thead>
          <tr>
            <th>#</th>
            <th>BidBond No.</th>
            <th>Ref</th>
            <th>Customer</th>
            <th>Currency</th>
            <th>Bid Value</th>
            <th>Commission</th>
            <th>Value Date</th>
            <th>Maturity Date</th>
            <th>Deal Date</th>
            <th>Beneficiary</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(bond, i) in bidbonds" :key="i">
            <td>{{ pagination.from + i }}</td>
            <td>{{ bond.bidbond_reference }}</td>
            <td>{{ bond.bidbond_secret }}</td>
            <td>{{ bond.company_name }}</td>
            <td>{{ bond.currency }}</td>
            <td>{{ $number.format(bond.tender_amount) }}</td>
            <td>{{ bond.exercise_duty }}</td>
            <td>{{ bond.tender_effective_date }}</td>
            <td>{{ getMaturityDate(bond.tender_effective_date, bond.tender_period) }}</td>
            <td>{{ bond.deal_date }}</td>
            <td>{{ bond.counter_party_name }}</td>
            <td><span class="badge badge-pill" :class="getPaidClass(bond.bidbond_paid)">{{
                pay_status(bond.bidbond_paid)
              }}</span></td>
            <td>
              <template v-if="bond.bidbond_paid">
                <router-link
                    v-if="$can.view($store.getters.getAuthUser, 'update-bidbonds')"
                    :to="{ 'name': 'bidbonds.edit', params: { id: bond.id} }"
                    class="btn btn-sm mr-2 btn-outline-primary">
                  <i class="fa fa-edit" /> Edit
                </router-link>
                <router-link :to="{ name: 'bidbonds.show', params: { id: bond.bidbond_secret }}"
                             class="btn btn-outline-info btn-sm">
                  <i class="fa fa-eye" /> View
                </router-link>
              </template>
              <template v-else>
                <router-link
                    v-if="isValid(bond)"
                    :to="{ name: 'bidbonds.payment', params: { id: bond.bidbond_secret, co: bond.company_id }}"
                    class="btn btn-outline-success btn-sm">
                  <i class="fa fa-money" /> Pay
                </router-link>
                <router-link
                    :to="{ name: 'bidbonds.create', params: { tender_no: bond.tender_number, company_id: bond.company_id }}"
                    class="btn btn-outline-primary mt-1 btn-sm">
                  <i class="fa fa-edit" /> Edit
                </router-link>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-warning">
          <p>There are no bidbonds generated</p>
        </div>
        <pagination v-if="pagination.last_page > 1" :pagination="pagination" :offset="5" @paginate="fetch" />
      </template>
      <template v-else>
        <loading />
      </template>
    </panel>
  </div>
</template>

<script>
import moment from 'moment/src/moment';
import {mapGetters} from "vuex";
import DatePicker from "vuejs-datepicker";

export default {
  components: {DatePicker},
  data() {
    return {
      start: {
        date: moment().add('week', '-1').toDate()
      },
      to: {
        date: moment().toDate()
      },
      search: '',
      bidbonds: [],
      is_loaded: false,
      pagination: {
        'per_page': 15,
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  computed: {
    ...mapGetters({
      pages: 'getPages'
    }),
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    },
    'pagination.per_page'() {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    exportBids() {
      this.is_loaded = false;
      this.$axios({
        url: `/api/v1/bid-bonds?export=xlsx&start=${moment(this.start.date).format('YYYY-MM-DD')}&end=${moment(this.to.date).format('YYYY-MM-DD')}`,
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((response) => {
        this.is_loaded = true;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'bidbonds_export.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/bid-bonds', {
        params: {
          per_page: this.pagination.per_page,
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.bidbonds = response.data.data;
        this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      }).catch(error => {
        if (error.response) {
          this.$error.handle(error.response);
        }
      });
    },
    isValid(bond) {
      return moment(bond.tender_effective_date).isSameOrAfter(moment(), 'day');
    },
    getMaturityDate(date, days) {
      return moment(moment(date, "YYYY-MM-DD").add('days', days)).format('YYYY-MM-DD');
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2500)
    },
    getPaidClass(status) {
      return status ? "badge-success" : "badge-warning";
    },
    pay_status(x) {
      return x ? 'Paid' : 'Unpaid';
    }
  }
}
</script>